import React from 'react';
import {RedocStandalone} from "redoc";

class ApiFetchedRedocSchema extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            error: null,
        }
    }

    componentDidMount() {
        fetch(this.props.apiSchema + '?version=' + this.props.apiVersion, {
            headers: {
                'Authorization': 'bearer ' + this.props.accessToken,
                'Accept': 'application/json',
            }
        })
        .then(async (res) => {
            if (res.status < 200 || res.status >= 300) {
                if (res.status === 401 || res.status === 403) {
                    this.props.onTokenFailed();
                }
                this.setState({
                    schema: null,
                    error: 'Client call failure status code ' + res.status,
                });
            }
            else {
                console.log(res);
                let result = await res.json();
                console.log(result);
                this.setState({
                    schema: result,
                });
            }
        });
    }

    render() {
        if (this.state.schema) {
            return <RedocStandalone spec={this.state.schema}/>
        }
        else if (this.state.error) {
            return 'Error: ' + this.state.error;
        }
        return 'Loading schema...'
    }
}

export default ApiFetchedRedocSchema;
