

function parseComponent(componentStr) {
    let result = {};
    let tmp = [];
    componentStr
        .substr(1)
        .split('&')
        .forEach(function (item) {
            tmp = item.split("=");
            result[tmp[0]] = tmp[1]
        });
    return result;
}

function parseFragment() {
    return parseComponent(window.location.hash);
}

function parseParameters() {
    return parseComponent(window.location.search);
}

function findGetParameter(parameterName) {
    let parameters = parseParameters();
    if (parameterName in parameters) {
        return parameters[parameterName];
    }
    return null;
}

export {parseFragment, parseParameters, findGetParameter};
