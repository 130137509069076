import React from 'react';
import {findGetParameter, parseFragment} from "./utils";


function getTokenFromFragment() {
    let fragment = parseFragment();
    if (fragment.state) {
        let ourState = sessionStorage.getItem('state');
        if (ourState === fragment.state && fragment.access_token) {
            sessionStorage.removeItem('state');
            return fragment.access_token;
        }
    }
    return null;
}

function getAccessToken(onVersionChanged) {
    // First look into the fragment
    let access_token = getTokenFromFragment();
    if (access_token) {
        console.log('Access token from fragment');
        sessionStorage.setItem('access_token', access_token);
        let url = new URL(window.location.href);
        url.hash = '';
        window.history.replaceState(null, '', url);
        let version = sessionStorage.getItem('version');
        console.log('Got version', version);
        if (version && onVersionChanged) {
            sessionStorage.removeItem('version');
            onVersionChanged(version);
        }
        return access_token;
    }
    // Then look into the session storage
    return sessionStorage.getItem('access_token');
}

function dec2hex(dec) {
    return dec < 10 ? '0' + String(dec) : dec.toString(16)
}

function randomString(len) {
    let crypto = (window.crypto || window.msCrypto);
    let arr = new Uint8Array((len || 40) / 2);
    crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('')

}

function getAuthorizationRequest(authorizationUrl, redirectUrl, clientId, apiVersion) {
    // Create random state and save in session storage
    let state = randomString();
    sessionStorage.setItem('state', state);
    sessionStorage.setItem('version', apiVersion);

    return authorizationUrl + '?response_type=token&scope=read&client_id=' + clientId + '&state=' + state + '&redirect_uri=' + encodeURIComponent(redirectUrl);
}


class OAuth2Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            access_token: null,
            loading_access_token: true,
            error: findGetParameter('error'),
        }
        this.tryAgain = this.tryAgain.bind(this);
        this.onTokenFailed = this.onTokenFailed.bind(this);
    }

    tryAgain() {
        this.setState(state => ({
            error: null,
            access_token: null,
            loading_access_token: true,
        }));
    }

    onTokenFailed() {
        console.log('Token failed. Delete from sessiont state', this);
        sessionStorage.removeItem('access_token');
        this.tryAgain();
    }

    componentDidMount() {
        let access_token = getAccessToken(this.props.onVersionChanged);
        this.setState({
            access_token: access_token,
            loading_access_token: false,
        })
    }

    render() {
        if (this.state.error) {
            return <div>
                <p>An error occurred during login: {this.state.error}</p>
                <button onClick={this.tryAgain}>Try again</button>
            </div>
        }
        else if (this.state.access_token) {
            let elements = React.Children.toArray(this.props.children);
            elements = elements.map((elem) => {
                return React.cloneElement(elem, {
                    accessToken: this.state.access_token,
                    onTokenFailed: this.onTokenFailed,
                });
            });
            return elements;
        }
        else if (this.state.loading_access_token) {
            return 'Loading...'
        }
        else {
            window.location.replace(getAuthorizationRequest(this.props.authorizationUrl, this.props.redirectUrl, this.props.clientId, this.props.apiVersion));
            return 'Redirecting to login...'
        }
    }
}

export default OAuth2Login;
