import './App.css';
import React from 'react';
import OAuth2Login from "./OAuth2Login";
import ApiFetchedRedocSchema from "./ApiFetchedRedocSchema";
import {parseParameters} from "./utils";

class App extends React.Component {
    constructor(props) {
        super(props);
        let parameters = parseParameters();
        let version = parameters['version'];
        if (!version) {
            version = 'latest';
        }
        this.state = {
            version: version,
        }

        this.onVersionChanged = this.onVersionChanged.bind(this);
    }

    onVersionChanged(version) {
        console.log('Version changed', version);
        let url = new URL(window.location.href);
        let searchParams = url.searchParams;
        if (version === 'latest') {
            searchParams.delete('version');
        }
        else {
            searchParams.set('version', version);
        }
        window.history.replaceState(null, '', url);

        this.setState({
            version: version
        })
    }

    render() {
        return (
            <OAuth2Login
                authorizationUrl={process.env.REACT_APP_AUTHORIZATION_URL}
                redirectUrl={process.env.REACT_APP_REDIRECT_URL}
                clientId={process.env.REACT_APP_CLIENT_ID}
                apiVersion={this.state.version}
                onVersionChanged={this.onVersionChanged}
            >
                <ApiFetchedRedocSchema apiSchema={process.env.REACT_APP_SCHEMA_URL} apiVersion={this.state.version}/>
            </OAuth2Login>
        )
    }
}

export default App;
